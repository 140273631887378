















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BannerPaymentHelper extends Vue {
  @Prop({ type: Object, default: () => {} }) paymentMethodsList: Object;

  get enabledPaymentMethods() {
    return Object.keys(this.paymentMethodsList).filter(paymentMethod => this.paymentMethodsList[paymentMethod]).length;
  }

  get getSubtitleMessage() {
    const message = this.enabledPaymentMethods <= 1 ? 'single-subtitle' : 'multiple-subtitle';

    return this.$t(`payment-help-to-sell.${message}`);
  }
}
